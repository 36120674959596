(function($) {
  $.fn.wpMenuDropdown = function( options ) {
    var settings = $.extend({
      clickToOpen: false,
      parentClickable: true,
      fadeDelay: 500,
    }, options );

    return this.each(function() {
      // Dropdown menus
      var $wrapper = $(this).parent();
      var $allMenus = $(this).find('.menu-item-has-children');
      var $allToggles = $allMenus.find('> a');
      var $allTopLinks = $(this).find('> li > a');

      // Timers
      var hoverTimer;
      var blurTimer;
      var delay = settings.fadeDelay;

      // Check if touch
      var isMobile = false;

      $(window).on('touchstart', function() {
        isMobile = true;
      });

      // Reusable functions
      function openMenu($current) {
        $allToggles.attr('aria-expanded', 'false');
        $current.attr('aria-expanded', 'true'); // aria-haspopup=true (changed)
        $current.parents('.menu-item-has-children').children('[aria-expanded]').attr('aria-expanded', 'true');
      }

      function closeMenu($current) {
        $current.attr('aria-expanded', 'false');
      }

      function focusSubmenu($current) {
        $current.on('transitionend', function() {
          if ($current.css('visibility') === 'visible') {
            $current.find('li:first-child a').focus();
            $current.off('transitionend');
          }
        });
      }

      // Add aria roles
      $('.menu-item.active > a').attr('aria-current', 'page');
      $allToggles.attr({ 'aria-expanded': 'false', 'aria-haspopup': 'true', 'role': 'button' });

      // Open menu on hover
      $allMenus.on('mouseenter', function(e) {
        if ( !isMobile && !settings.clickToOpen ) {
          openMenu( $(this).children('[aria-expanded]') );

          clearTimeout(hoverTimer);
        }
      });

      // Toggle menu on click, tap, or focus + enter/space
      $allToggles.on('click', function(e) {
          var $this = $(this);
          var $submenu = $this.next('.sub-menu');

          if ($this.attr('aria-expanded') === 'true') closeMenu($this);
          else openMenu($this);

          if ( $(this).parent().hasClass('open-link') ) {
            return;
          }

          if ( !settings.parentClickable || isMobile || settings.clickToOpen || $(this).parent().hasClass('toggle-only') ) {
            e.preventDefault();
          }
        })
        .on('keydown', function(e) {
          if (e.keyCode === 32 || e.keyCode === 13 && !settings.parentClickable ) {
            openMenu($(this));
            focusSubmenu($(this).next('.sub-menu'));
            e.preventDefault();
          }
        });

      // Close menu after a short delay
      $allMenus.on('mouseleave', function() {
        if ( !isMobile && !settings.clickToOpen ) {
          var $element = $(this).find('[aria-expanded]');

          hoverTimer = setTimeout(function() {
            closeMenu($element);
          }, delay);
        }
      });

      // Close menu when refocusing on top-level links
      $allTopLinks.on('focus', function() {
        if ( !isMobile && !settings.clickToOpen ) {
          closeMenu($allToggles);
        }
      });

      // Close menu on esc
      $wrapper.on('keyup', function(e) {
        if (e.keyCode === 27) closeMenu($allToggles);
      });

      // Close menu if focus isn't inside site navigation
      $(this).find('.sub-menu').on('focusout', function() {
        // There's a delay between focusout and re-focus
        setTimeout( function() {
          var $focused = $(document.activeElement);
          if($focused.closest( $wrapper ).length === 0 ) {
            closeMenu($allToggles);
          }
        }, 1);
      });
    });
  };
})(jQuery);

