(function($) {
  window.OCWMG = {};

  OCWMG = {
    initMenus: function() {
      $('#menu-primary-menu').wpMenuDropdown({ parentClickable: false });
      $('.js-toggle').wpToggle();

      $('.main-navigation-wrapper').on('click', function(e) {
        if( $(e.target).hasClass('main-navigation-wrapper') ) {
          $('.btn-back').click();
        }
      });
    },

    modalEnhancements: function() {
      $('.modal').appendTo('body');
    },

    initSlideshow: function() {
      let $bgslideshow = $('.background-slideshow');

      $('.slideshow').slick({
        prevArrow: '<button class="slideshow-arrow btn-prev"><span>Back</span></button>',
        nextArrow: '<button class="slideshow-arrow btn-next"><span>Next</span></button>',
        appendArrows: $('.slideshow-arrows'),
        autplay: false,
        rows: 0,
        dots: true,
      });

      $('.home-address').on('mouseenter', function() {
        let index = $(this).index() - 2;

       $bgslideshow.slick('slickGoTo', index );
      });
    },

    formEnhancements: function() {
      $('[data-toggle="tooltip"]').tooltip({
        container: 'body'
      });

      $('input, textarea, select').on('focus', function() {
        let id = $(this).attr('id');
        let label = $('label[for="' + id + '"]');

        label.addClass('label-focus');
      }).on('blur', function() {
        $('.label-focus').removeClass('label-focus');
      }).on('change', function() {
        let id = $(this).attr('id');
        let label = $('label[for="' + id + '"]');

        if ( $(this).val() ) {
          label.addClass('label-value');
        } else {
          label.removeClass('label-value');
        }
      });
    },

    dismissableBubble: function() {
      $('.bubble').each(function() {
        var $bubble = $(this);
        $bubble.prepend('<button class="btn-plain btn-bubble"><i class="icon-css icon-close"></i><span class="icon-label sr-only">Close</span></button>');

        $bubble.on('click', '.btn-bubble',function() {
          $bubble.addClass('hidden');
        });

        $bubble.on('transitionend', function() {
          if( $bubble.hasClass('hidden') ) $bubble.attr('aria-hidden', 'true');
        });
      });
    },

    init: function() {
      this.initMenus();
      this.modalEnhancements();
      this.initSlideshow();
      this.formEnhancements();
      this.dismissableBubble();
    }
  }

  $(document).ready(function() {
    OCWMG.init();
  });

})(jQuery);
