(function($){
  $.fn.wpToggle = function( options ) {
    var settings = $.extend({
      targetClass: 'toggle-target',
    }, options );

    // Set up classes and attributes
    var $allToggles = $(this);
    $allToggles.attr('aria-expanded', 'false');

    $allToggles.each(function() {
      let $toggle = $(this);
      let $target = $( '#' + $toggle.attr('aria-controls') );

      $target.attr('aria-hidden', 'true');
      $target.addClass(settings.targetClass);
    });

    $allToggles.on('click', function() {
      let control = $(this).attr('aria-controls');
      let $toggle = $('[aria-controls=' + control + ']');
      let $target = $( '#' + control );

      // Open target
      if ( $(this).attr('aria-expanded') === 'false' ) {
        $toggle.attr('aria-expanded', 'true');
        $target.attr('aria-hidden', 'false');

        $('body').addClass('toggled-' + $target.attr('id'));
      }
      // Close target
      else {
        $toggle.attr('aria-expanded', 'false');
        $target.attr('aria-hidden', 'true');

        $('body').removeClass('toggled-' + $target.attr('id'));
      }
    });

    return this;
  };
})(jQuery);
